let eventsEl;
let activeMapClass = 'events-map-is-active';

function checkView(options) {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('map') === 'true') {
    showMap(options);
  } else {
    showList(options);
  }
}

function showList(options = {updateHistory: true}) {
  if (eventsEl) {
    eventsEl.classList.remove(activeMapClass);
    // Update URL to reflect map view
    const urlObject = new URL(window.location);
    urlObject.searchParams.delete('map', 'true');
    if (options.updateHistory) {
      history.pushState(null, '', urlObject.toString());  
    }
  };
}

function showMap(options = {updateHistory: true}) {;
  if (eventsEl) {
    eventsEl.classList.add(activeMapClass);
    // Update URL to reflect map view
    const urlObject = new URL(window.location);
    urlObject.searchParams.set('map', 'true');
    if (options.updateHistory) {
      history.pushState(null, '', urlObject.toString());  
    }
  };
}

const eventsView = {
  init() {
    document.addEventListener("change", function (e) {
      const clicked = e.target;
      const eventsMapBtn = clicked.closest("#events-map-btn");
      const eventsListBtn = clicked.closest("#events-list-btn");
      eventsEl = document.getElementById('events');
      if (eventsListBtn) {
        showList();
      } else if (eventsMapBtn) {
        showMap();
      }
    });
  },
  checkView
};

export default eventsView;