import { Loader } from "@googlemaps/js-api-loader";
import axios from "axios";

let markers = [];
let infoWindow;
let map;
let markerClass;
let mapContainer;

function updateMarkers(urlParam) {
  // See which page you are currently on
  const currentPagePath = window.location.pathname;

  // Make sure request url is formatted correctly
  let url =
    urlParam || `${location.origin}${currentPagePath}.json${location.search}`;
  if (!url.includes(`${currentPagePath}.json`)) {
    url = url.replace(`${currentPagePath}`, `${currentPagePath}.json`);
  }

  console.log(url);

  // Get a matching list of projects
  axios.get(url).then((res) => {
    const items = res.data.data;

    items.forEach((item) => {
      if (!item.latLng) return;
      const lat = item.latLng.split(",")[0];
      const lng = item.latLng.split(",")[1];
      const heading = item.title;
      const url = item.url;

      if (!lat || !lng) return;

      const contentString =
        '<div class="pin-info">' +
        '<h4 class="pin-info__heading">' +
        heading +
        "</h4>" +
        '<a class="pin-info__link" href="' +
        url +
        '">' +
        "Find out more</a> " +
        "</div>";

      const marker = new markerClass({
        map,
        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
        title: heading,
        gmpClickable: true,
      });

      // Add a click listener for each marker, and set up the info window.
      marker.addListener("click", ({ domEvent, latLng }) => {
        const { target } = domEvent;

        infoWindow.close();
        infoWindow.setContent(contentString);
        infoWindow.open(marker.map, marker);
      });

      markers.push(marker);
    });
  });
}

function init() {
  mapContainer = document.getElementById("events-map-embed");
  if (!mapContainer) return;

  const loader = new Loader({
    apiKey: "AIzaSyBOu0xJB2Dmae9R5bW2DfSyIoIIV34Iopg",
  });

  loader
    .load()
    .then(async () => {
      const { Map, InfoWindow } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        "marker"
      );
      markerClass = AdvancedMarkerElement;

      map = new Map(mapContainer, {
        center: { lat: 51.509865, lng: -0.118092 },
        mapId: "7bc055b4a4fc272e",
        zoom: 8,
      });

      // Create an info window to share between markers.
      infoWindow = new InfoWindow({
        maxWidth: 250,
      });
    })
    .then(() => {
      updateMarkers();
    })
    .catch((error) => {
      // Handle any errors
      console.error("Error occurred:", error);
    });
}

const projectsMap = {
  init,
  updateMarkers,
};

export default projectsMap;
