// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

import tabbing from './modules/tabbing';
import skip from './modules/skip';
import nav from './modules/nav';
import videos from './modules/videos';
import animtext from './modules/animtext';
import accordion from './modules/accordion';
import eventsView from './modules/events-view';
import eventsMap from './modules/events-map';

tabbing();
skip();
nav();
videos();
accordion();
eventsView.init();
eventsMap.init();

const rootEL = document.documentElement;
rootEL.classList.remove('no-js');

window.addEventListener('load', function(e) {
  document.body.classList.remove('page-is-loading');
  animtext();
});

